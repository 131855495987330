import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

// eslint-disable-next-line
export const AboutPageTemplate = ({ title, header, content, image, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient" style={{
      backgroundImage:"url(/img/globe-bg.jpg)",
      color: "white",
    }}>
      <div className="container">
        <div className="columns">
          <div className="column is-5 is-offset-1">
            <div className="section" style={{
              height: "100%",
            }}>
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light" style={{
                color: "#30CAFF",
              }}>
                {header}
              </h2>
              <PageContent className="content" content={content} />
              <br></br>
              <p><a href="/contact" style={{
                color: "#FF6633",
              }}>Get in touch today</a> or go back to <a href="/partners" style={{
                color: "#FF6633",
              }}>Partners.</a></p>
            </div>
          </div>
          <div className="column" style={{
            marginTop: "3em",
            marginLeft: "3em",
          }}>
            <PreviewCompatibleImage imageInfo={image} />
            </div>
        </div>
      </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        header={post.frontmatter.header} 
        image={post.frontmatter.image}
        path={post.frontmatter.path}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        header
        path
        image {
          childImageSharp {
            gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
